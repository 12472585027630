import React, {
  FunctionComponent,
  useState,
  useCallback,
  useEffect,
} from 'react';
import styled, { css } from 'styled-components';
import { useRouter } from 'next/router';
import { Colors as StyleColors } from 'styles/colors';
import Logotype from 'public/images/logotype.svg';
import Alumni from 'public/images/earth-prize-alumni.svg';
import Volunteer from 'public/images/earth-prize-volunteer.svg';
import EcoClubs from 'public/images/ecoClubs/icons/logo.svg';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Section } from 'components/Section/Section';
import { YearsSection } from 'components/YearsSection/YearsSection';
import { H2, H3 } from 'components/Typography/Typography';
import { useMediaQuery } from 'react-responsive';
import { Consts as StyleConsts } from 'styles/consts';
import { Socials } from 'components/Socials/Socials';
import { headerColors, scrollTop } from 'utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faGraduationCap } from '@fortawesome/free-solid-svg-icons';
import { useSession } from 'next-auth/client';
import Link from 'next/link';
import { Spacer } from './Spacer';
import Language from './Language';

interface IHeader {
  withoutLogin?: boolean;
}

const getYearsSubmenu = (year: string, handleMenuOpen: any) => {
  switch (year) {
    case '2024':
      return (
        <>
          <Link
            href="/the-earth-prize-2024"
            onClick={() => handleMenuOpen(false, false)}
          >
            <H3>The Winner and Runners-up</H3>
          </Link>
          <Link href="/finalists" onClick={() => handleMenuOpen(false, false)}>
            <H3>The Finalists</H3>
          </Link>
          <Link href="/scholars" onClick={() => handleMenuOpen(false, false)}>
            <H3>The Scholars</H3>
          </Link>
          <Link
            href="/2024-educator"
            onClick={() => handleMenuOpen(false, false)}
          >
            <H3>The Educator of The Year</H3>
          </Link>
          <Link
            href="/2024-mentor"
            onClick={() => handleMenuOpen(false, false)}
          >
            <H3>The Mentors of The Year</H3>
          </Link>
          <Link
            href="/the-earth-prize-2024-ceremony"
            onClick={() => handleMenuOpen(false, false)}
          >
            <H3>The Awards Ceremony</H3>
          </Link>
        </>
      );
    case '2023':
      return (
        <>
          <Link
            href="/the-earth-prize-2023"
            onClick={() => handleMenuOpen(false, false)}
          >
            <H3>The Winner and Runners-up</H3>
          </Link>
          <Link
            href="/2023-educator"
            onClick={() => handleMenuOpen(false, false)}
          >
            <H3>The Educator of The Year</H3>
          </Link>
          <Link
            href="/2023-mentor"
            onClick={() => handleMenuOpen(false, false)}
          >
            <H3>The Mentors of The Year</H3>
          </Link>
          <Link
            href="/2023-finalists"
            onClick={() => handleMenuOpen(false, false)}
          >
            <H3>The Finalists</H3>
          </Link>
          <Link
            href="/2023-scholars"
            onClick={() => handleMenuOpen(false, false)}
          >
            <H3>The Scholars</H3>
          </Link>
          <Link
            href="/the-earth-prize-2023-ceremony"
            onClick={() => handleMenuOpen(false, false)}
          >
            <H3>The Awards Ceremony</H3>
          </Link>
        </>
      );
    case '2022':
      return (
        <>
          <Link
            href="/the-earth-prize-2022"
            onClick={() => handleMenuOpen(false, false)}
          >
            <H3>The Winner and Runners-up</H3>
          </Link>
          <Link
            href="/2022-educator"
            onClick={() => handleMenuOpen(false, false)}
          >
            <H3>The Educator of The Year</H3>
          </Link>
          <Link
            href="/2022-mentor"
            onClick={() => handleMenuOpen(false, false)}
          >
            <H3>The Mentor of The Year</H3>
          </Link>
          <Link
            href="/2022-finalists"
            onClick={() => handleMenuOpen(false, false)}
          >
            <H3>The Finalists</H3>
          </Link>
          <Link
            href="/2022-scholars"
            onClick={() => handleMenuOpen(false, false)}
          >
            <H3>The Scholars</H3>
          </Link>
          <Link
            href="/the-earth-prize-2022-ceremony"
            onClick={() => handleMenuOpen(false, false)}
          >
            <H3>The Awards Ceremony</H3>
          </Link>
        </>
      );
    default:
      return null;
  }
};

export const Header: FunctionComponent<IHeader> = ({ withoutLogin }) => {
  const router = useRouter();
  const [session, loading] = useSession();
  const [prevScrollPosition, setPrevScrollPosition] = useState(0);
  const [isHeaderVisible, setVisible] = useState(true);
  const [isHeaderWithBackground, setHeaderWithBackground] = useState(false);
  const [isMenuOpened, openMenu] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [openedYearSection, openYearSection] = useState(null);
  const [lastName, setLastName] = useState('');
  const [headerColor, setHeaderColor] = useState(StyleColors.homeHeaderColor);
  const [headerBackgroundColor, setHeaderBackgroundColor] = useState(
    StyleColors.homeHeaderBackgroundColor,
  );
  const [headerLoginColor, setHeaderLoginColor] = useState(
    StyleColors.homeHeaderColor,
  );
  const mobile = useMediaQuery({
    query: `(max-width: ${StyleConsts.smallTabletBreakPoint})`,
  });
  const [isMobile, setMobile] = useState(false);
  useEffect(() => {
    // @ts-ignore
    !loading && session && setFirstName(session.user.firstName);
    // @ts-ignore
    !loading && session && setLastName(session.user.lastName);
  }, [session, loading]);

  useEffect(() => {
    setMobile(mobile);
  }, [mobile]);

  useEffect(() => {
    headerColors(
      router,
      setHeaderColor,
      setHeaderBackgroundColor,
      setHeaderLoginColor,
      isMobile,
    );
  }, [router, setHeaderColor, setHeaderBackgroundColor, isMobile]);

  const handleScroll = useCallback(() => {
    const currentScrollPos = window.pageYOffset;
    setPrevScrollPosition(currentScrollPos);
    const headerWithBackgroundOffset = 50;
    setHeaderWithBackground(currentScrollPos > headerWithBackgroundOffset);
    setVisible(prevScrollPosition > currentScrollPos || currentScrollPos < 50);
    document.documentElement.style.setProperty(
      '--scroll-y',
      `${window.scrollY}px`,
    );
  }, [prevScrollPosition, setPrevScrollPosition, isMobile]);

  useEffect(() => {
    handleScroll();
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  const handleMenuOpen = (shouldOpenMenu, shouldScrollTop) => {
    const { body } = document;
    openMenu(shouldOpenMenu);

    if (shouldOpenMenu) {
      const scrollY =
        document.documentElement.style.getPropertyValue('--scroll-y');
      body.style.position = 'fixed';
      body.style.top = `-${scrollY}`;
    } else {
      const scrollY = body.style.top;
      body.style.position = '';
      body.style.top = '';
      window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);

      if (shouldScrollTop) {
        scrollTop();
      } else {
        window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
      }
    }
  };

  const onRoute = useCallback(
    (path) => {
      router.push(path);
      handleMenuOpen(false, true);
    },
    [router],
  );

  const handleMenuLogoClick = () => {
    handleMenuOpen(false, true);
    scrollTop();
  };

  return (
    <HeaderOuterWrapper
      isScrolled={isHeaderVisible}
      headerBackgroundColor={headerBackgroundColor}
      isHeaderWithBackground={isHeaderWithBackground}
    >
      <HeaderInnerWrapper>
        <LogoLink
          href={router.pathname === '/course' ? '/account' : '/'}
          onClick={() => scrollTop()}
          headerColor={
            router.pathname !== '/volunteer-community-faq' &&
            router.pathname !== '/volunteer' &&
            headerColor
          }
        >
          {router.pathname === '/alumni' && <Alumni id="alumni" />}
          {router.pathname === '/eco-clubs' && <EcoClubs id="ecoClubs" />}
          {(router.pathname === '/volunteer-community-faq' ||
            router.pathname === '/volunteer') && <Volunteer id="volunteer" />}
          {router.pathname !== '/alumni' &&
            router.pathname !== '/volunteer-community-faq' &&
            router.pathname !== '/eco-clubs' &&
            router.pathname !== '/volunteer' && <Logotype id="logo" />}
        </LogoLink>
        <UserWrapper
          isOpened={isMenuOpened}
          headerColor={
            isHeaderWithBackground || isMobile ? headerColor : headerLoginColor
          }
        >
          {!withoutLogin &&
            router.pathname !== '/course' &&
            !router.pathname.includes('/2025') && (
              <>
                {/* {!isMobile && <a href="/registration">REGISTER</a>} */}
                {session && (
                  <Link href="/account">
                    <FontAwesomeIcon
                      icon={faUser as IconProp}
                      width="0.875em"
                    />
                    {!isMobile && 'My Earth Prize'}
                  </Link>
                )}
                {!session && router.pathname !== '/login' && (
                  <Link href="/login">
                    <FontAwesomeIcon
                      icon={faUser as IconProp}
                      width="0.875em"
                    />
                    {!isMobile && 'LOG IN'}
                  </Link>
                )}
                {!isMobile && (
                  <Link href="/alumni">
                    <FontAwesomeIcon
                      icon={faGraduationCap as IconProp}
                      height="0.875em"
                    />
                    ALUMNI
                  </Link>
                )}
              </>
            )}
          {router.pathname.includes('/2025') && !isMenuOpened && (
            <>
              {/* {!isMobile && <a href="/registration">REGISTER</a>} */}
              <Language />
            </>
          )}
          {!isMobile && router.pathname === '/schools' && (
            <>
              <Link href="/schools-es">EN ESPAÑOL</Link>
              <Link href="/schools-fr">EN FRANÇAIS</Link>
            </>
          )}
          {!isMobile && router.pathname === '/schools-es' && (
            <>
              <Link href="/schools">IN ENGLISH</Link>
              <Link href="/schools-fr">EN FRANÇAIS</Link>
            </>
          )}
          {!isMobile && router.pathname === '/schools-fr' && (
            <>
              <Link href="/schools">IN ENGLISH</Link>
              <Link href="/schools-es">EN ESPAÑOL</Link>
            </>
          )}
          {isMobile && router.pathname === '/schools' && (
            <>
              <Link href="/schools-es" style={{ margin: '-5px 15px 0 0' }}>
                <img height={16} src="/images/spain.png" alt="spain" />
              </Link>
              <Link href="/schools-fr" style={{ margin: '-5px 5px 0 0' }}>
                <img height={16} src="/images/france.png" alt="france" />
              </Link>
            </>
          )}
          {isMobile && router.pathname === '/schools-es' && (
            <>
              <Link href="/schools" style={{ margin: '-5px 15px 0 0' }}>
                <img height={16} src="/images/britain.png" alt="britain" />
              </Link>
              <Link href="/schools-fr" style={{ margin: '-5px 5px 0 0' }}>
                <img height={16} src="/images/france.png" alt="france" />
              </Link>
            </>
          )}
          {isMobile && router.pathname === '/schools-fr' && (
            <>
              <Link href="/schools" style={{ margin: '-5px 15px 0 0' }}>
                <img height={16} src="/images/britain.png" alt="britain" />
              </Link>
              <Link href="/schools-es" style={{ margin: '-5px 5px 0 0' }}>
                <img height={16} src="/images/spain.png" alt="spain" />
              </Link>
            </>
          )}
          {router.pathname === '/course' && (
            <UserNameWrapper onClick={() => router.push('/profile')}>
              {(firstName || lastName) && (
                <>
                  <p>
                    {firstName} {lastName}
                  </p>
                  <UserLogo>{firstName[0] + lastName[0]}</UserLogo>
                </>
              )}
            </UserNameWrapper>
          )}
          {router.pathname !== '/course' && (
            <MenuButton
              headerColor={
                isHeaderWithBackground || isMobile
                  ? headerColor
                  : headerLoginColor
              }
              onClick={() => handleMenuOpen(!isMenuOpened, false)}
              isOpened={isMenuOpened}
            >
              <div />
              <div />
              <div />
            </MenuButton>
          )}
        </UserWrapper>
        <MobileMenu isOpened={isMenuOpened}>
          <MenuLogoLink href="/" onClick={() => handleMenuLogoClick()}>
            <Logotype />
          </MenuLogoLink>
          <MenuContent>
            <MenuLinksWrapper>
              {/* !session && (
                  <>
                    <Section
                      name={
                        <H2 onClick={() => onRoute('/pre-registration')}>
                          Pre-register
                        </H2>
                      }
                      isButton
                      noBorder
                    />
                    <Section
                      name={<H2 onClick={() => onRoute('/login')}>Log in</H2>}
                      isButton
                      noBorder
                    />
                    <Spacer height={50} />
                  </>
                ) */}
              <Section
                isButton
                name={
                  <H2>
                    <Link
                      href="/2025"
                      onClick={() => handleMenuOpen(false, false)}
                    >
                      The Earth Prize 2025
                    </Link>
                  </H2>
                }
              />
              <YearsSection
                name={
                  <H2>
                    <Link
                      href="/the-earth-prize-2024"
                      onClick={() => handleMenuOpen(false, false)}
                    >
                      WINNERS & FINALISTS
                    </Link>
                  </H2>
                }
                buttonLabels={['2024', '2023', '2022']}
                openedSection={openedYearSection}
                openSection={openYearSection}
              >
                {getYearsSubmenu(openedYearSection, handleMenuOpen)}
              </YearsSection>
              <Section
                name={
                  <H2>
                    <Link
                      href="/learning-content"
                      onClick={() => handleMenuOpen(false, false)}
                    >
                      LEARNING CONTENT
                    </Link>
                  </H2>
                }
              >
                <Link
                  href="/learning-content"
                  onClick={() => handleMenuOpen(false, false)}
                >
                  <H3>Bespoke Learning</H3>
                </Link>
              </Section>
              <Section
                name={
                  <H2>
                    <Link
                      href="/competition"
                      onClick={() => handleMenuOpen(false, false)}
                    >
                      The Competition
                    </Link>
                  </H2>
                }
              >
                <Link
                  href="/competition#basics"
                  onClick={() => handleMenuOpen(false, false)}
                >
                  <H3>The Basics</H3>
                </Link>
                <Link
                  href="/competition#timeline"
                  onClick={() => handleMenuOpen(false, false)}
                >
                  <H3>The Timeline</H3>
                </Link>
                <Link
                  href="/competition#evaluation-criteria"
                  onClick={() => handleMenuOpen(false, false)}
                >
                  <H3>Evaluation Criteria</H3>
                </Link>
                <Link
                  href="/competition#adjudicating-panel"
                  onClick={() => handleMenuOpen(false, false)}
                >
                  <H3>The Adjudicating Panel</H3>
                </Link>
                <Link
                  href="/schools"
                  onClick={() => handleMenuOpen(false, false)}
                >
                  <H3>Information for Schools</H3>
                </Link>
                <Link href="/map" onClick={() => handleMenuOpen(false, false)}>
                  <H3>Participating Countries</H3>
                </Link>
              </Section>
              <Section
                name={
                  <H2>
                    <Link
                      href="/about-us"
                      onClick={() => handleMenuOpen(false, false)}
                    >
                      About us
                    </Link>
                  </H2>
                }
              >
                <Link
                  href="/about-us#ecosystem"
                  onClick={() => handleMenuOpen(false, false)}
                >
                  <H3>The Ecosystem</H3>
                </Link>
                <Link
                  href="/about-us#youth-board"
                  onClick={() => handleMenuOpen(false, false)}
                >
                  <H3>The Youth Board</H3>
                </Link>
                <Link
                  href="/about-us#ambassadors"
                  onClick={() => handleMenuOpen(false, false)}
                >
                  <H3>The Ambassadors</H3>
                </Link>
                <Link
                  href="/about-us#adjudicating"
                  onClick={() => handleMenuOpen(false, false)}
                >
                  <H3>The Adjudicating Panel</H3>
                </Link>
                <Link
                  href="/about-us#team"
                  onClick={() => handleMenuOpen(false, false)}
                >
                  <H3>The Team</H3>
                </Link>
                <Link
                  href="/mentors"
                  onClick={() => handleMenuOpen(false, false)}
                >
                  <H3>The Mentors</H3>
                </Link>
                <Link
                  href="/about-us#get-involved"
                  onClick={() => handleMenuOpen(false, false)}
                >
                  <H3>Get Involved</H3>
                </Link>
              </Section>
              <Spacer height={30} />
              {isMobile && (
                <Section
                  noBorder
                  name={
                    <H2 onClick={() => onRoute('/alumni')}>
                      Alumni&nbsp;&nbsp;
                      <FontAwesomeIcon icon={faGraduationCap as IconProp} />
                    </H2>
                  }
                  isButton
                />
              )}
              {/* isMobile && (
                <Section
                  noBorder
                  name={
                    <H2
                      color="white !important"
                      onClick={() => onRoute('/registration')}
                    >
                      Register
                    </H2>
                  }
                  isButton
                />
              ) */}
              <Spacer height={70} />
              <Socials background="#0AB7BC" color="black" />
            </MenuLinksWrapper>
          </MenuContent>
        </MobileMenu>
      </HeaderInnerWrapper>
    </HeaderOuterWrapper>
  );
};

interface ILogoLink {
  headerColor: string;
}

const LogoLink = styled.a<ILogoLink>`
  ${({ headerColor, theme: { Consts } }) => css`
    path {
      fill: ${headerColor};
    }

    @media (max-width: ${Consts.tabletBreakPoint}) {
      width: 200px;
    }

    #logo {
      transform: scale(1.4);
      position: relative;
      top: 5px;
      left: 35px;

      @media (max-width: ${Consts.tabletBreakPoint}) {
        transform: scale(1);
        top: auto;
        left: auto;
      }
    }

    #alumni {
      top: 5px;
      position: relative;
      transform: scale(1);
      left: 0;

      @media (max-width: ${Consts.tabletBreakPoint}) {
        transform: scale(0.7);
        left: -36px;
        top: 0;
      }
    }

    #ecoClubs {
      top: 8px;
      position: relative;
      transform: scale(0.75);
      left: -43px;

      @media (max-width: ${Consts.tabletBreakPoint}) {
        transform: scale(0.55);
        left: -76px;
        top: 2px;
      }
    }

    #volunteer {
      top: 8px;
      position: relative;
      transform: scale(0.9);
      left: -15px;

      @media (max-width: ${Consts.tabletBreakPoint}) {
        transform: scale(0.67);
        left: -50px;
        top: 0;
      }
    }
  `}
`;

const MenuLogoLink = styled.a`
  ${({ theme: { Colors, Consts } }) => css`
    position: relative;
    top: 0;
    left: 15px;

    @media (max-width: ${Consts.tabletBreakPoint}) {
      top: -20px;
      left: -10px;
    }

    svg {
      transform: scale(1.4);

      @media (max-width: ${Consts.tabletBreakPoint}) {
        transform: scale(1);
      }

      path {
        fill: ${Colors.menuLogoColor};
      }
    }
  `}
`;

interface IHeaderOuterWrapper {
  isScrolled: boolean;
  isHeaderWithBackground: boolean;
  headerBackgroundColor: string;
}

const HeaderOuterWrapper = styled.header<IHeaderOuterWrapper>`
  ${({
    headerBackgroundColor,
    isHeaderWithBackground,
    isScrolled,
    theme: { Consts },
  }) => css`
    width: 100vw;
    position: fixed;
    left: 0;
    z-index: 40;
    transition: all 0.5s;
    height: 110px;
    background-color: ${isHeaderWithBackground
      ? headerBackgroundColor
      : `transparent`};
    top: ${isScrolled ? `0` : `-150px`};

    @media (max-width: ${Consts.tabletBreakPoint}) {
      height: 80px;
    }
  `}
`;

const HeaderInnerWrapper = styled.div`
  ${({ theme: { Consts } }) => css`
    width: 100%;
    height: 100%;
    position: relative;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    border-radius: 50px;
    transition: all 0.2s;
    @media (min-width: ${Consts.mobileBreakPoint}) {
      padding: 0 40px 0 40px;
    }
    @media (min-width: ${Consts.tabletBreakPoint}) {
      padding: 0 60px 0 60px;
    }
  `}
`;

const UserWrapper = styled.div<IMenuButton>`
  ${({ isOpened, headerColor, theme: { Consts, Colors } }) => css`
    display: flex;
    align-items: center;
    z-index: 1;

    > a {
      font-size: 20px;
      margin-right: 40px;
      font-weight: bold;
      color: ${isOpened ? Colors.menuButtonColorOpened : headerColor};
      cursor: pointer;
      position: relative;
      top: -1px;

      svg {
        margin-right: 15px;
      }
    }

    @media (max-width: ${Consts.mobileBreakPoint}) {
      > a {
        margin-right: 0;
        font-size: 26px;
      }
    }
  `}
`;

interface IMenuButton {
  isOpened: boolean;
  headerColor: string;
}
const UserNameWrapper = styled.div`
  ${({ theme: { Consts } }) => css`
    display: flex;
    align-items: center;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;
    gap: 11.73px;
    cursor: pointer;

    @media (max-width: ${Consts.mobileBreakPoint}) {
      font-size: 12px;
      line-height: 24px;
      gap: 10px;
    }
  `}
`;
const UserLogo = styled.div`
  ${({ theme: { Consts } }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f1f1f1;
    width: 44.24px;
    height: 44.98px;
    color: #000000;
    border-radius: 50%;
    text-transform: uppercase;
    @media (max-width: ${Consts.mobileBreakPoint}) {
      width: 32px;
      height: 32px;
    }
  `}
`;
const MenuButton = styled.button<IMenuButton>`
  ${({ headerColor, isOpened, theme: { Colors } }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 55px;
    width: 55px;
    background-color: transparent;
    border: 0;
    border-radius: 50%;
    padding: 0;
    z-index: 1;
    transition: all 0.2s;
    cursor: pointer;
    transform: ${isOpened ? `rotate(90deg)` : `rotate(0deg)`};
    top: ${isOpened ? `-4px` : `0`};
    position: relative;

    > div {
      background-color: ${isOpened
        ? Colors.menuButtonColorOpened
        : headerColor};
      height: 5px;
      border-radius: 2px;
      width: 30px;
      margin-bottom: 5px;
    }
  `}
`;

const MobileMenu = styled.div<{ isOpened: boolean }>`
  ${({ theme: { Consts, Colors }, isOpened }) => css`
    position: fixed;
    top: 0;
    left: 0;
    background-color: ${Colors.mobileMenuBackground};
    height: 100vh;
    width: 100vw;
    padding: 32px 80px;
    overflow-y: scroll;
    display: ${isOpened ? 'block' : 'none'};

    @media (max-width: ${Consts.tabletBreakPoint}) {
      padding: 32px 30px 90px;
    }
  `}
`;

const MenuContent = styled.div`
  ${({ theme: { Colors, Consts } }) => css`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 70px;
    padding-right: 50px;

    @media (max-width: ${Consts.tabletBreakPoint}) {
      flex-direction: column;
      margin-top: 25px;
      padding-right: 0;
    }

    > div {
      &:nth-child(2) {
        flex-direction: column;

        @media (max-width: ${Consts.tabletBreakPoint}) {
          flex-direction: row;
          margin-top: 36px;
        }

        > div {
          background-color: ${Colors.menuLinksWrapperColor};
          margin-right: 0;

          @media (max-width: ${Consts.tabletBreakPoint}) {
            margin-right: 5px;
          }

          svg {
            path {
              fill: ${Colors.mobileMenuBackground};
            }
          }
        }
      }
    }
  `}
`;

const MenuLinksWrapper = styled.div`
  ${({ theme: { Colors, Consts } }) => css`
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    max-width: 900px;

    > div {
      border-bottom-color: ${Colors.menuLinksWrapperColor};

      > div {
        &:nth-child(1) {
          border-bottom-color: ${Colors.menuLinksWrapperColor};
          padding: 30px 0 25px;

          @media (max-width: ${Consts.tabletBreakPoint}) {
            padding: 15px 0 10px;
          }

          > div {
            > h2 {
              color: ${Colors.menuLinksWrapperColor};
              font-size: 75px;
              line-height: 80px;
              cursor: pointer;

              @media (max-width: ${Consts.tabletBreakPoint}) {
                font-size: 52px;
                line-height: 52px;
              }

              @media (max-width: ${Consts.mobileBreakPoint}) {
                font-size: 32px;
                line-height: 34px;
              }
            }
          }

          a {
            color: ${Colors.menuLinksWrapperColor};
          }

          > button {
            background-color: ${Colors.menuLinksWrapperColor};
            color: ${Colors.menuLinksWrapperActionButtonIconColor};
            position: relative;
            top: -9px;
          }
        }

        h3 {
          color: ${Colors.menuLinksWrapperColor};
          font-size: 36px;

          @media (max-width: ${Consts.tabletBreakPoint}) {
            font-size: 26px;
          }

          @media (max-width: ${Consts.mobileBreakPoint}) {
            font-size: 20px;
          }
        }
      }
    }
  `}
`;
